import React from "react";
import Layout from "../components/layout/layout";
import LatestPosts from "../components/blocks/latest-posts";
import "./frontpage.scss"

export default () => {

  return (
    <Layout>
      <main>
        <section>

          <LatestPosts/>

        </section>
      </main>
    </Layout>
  )
};

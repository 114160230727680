import React from "react";
import "./recipe-teaser.scss"
import Img from "gatsby-image";
import {Link} from "gatsby";

const RecipeTeaser = (props) => {

  return (
    <Link className="article-teaser" to={props.link}>
      <Img fluid={{...props.image, sizes: '(max-width: 400px) 100px, (max-width: 800px) 200px, 400px'}} />
      <div className="article-info">
        <h3>{props.title}</h3>
      </div>
    </Link>
  )
};

export default RecipeTeaser;

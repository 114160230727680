import React from "react";
import "./latest-posts.scss"
import {graphql, useStaticQuery} from "gatsby";
import RecipeTeaser from "../node/recipe-teaser";
import ArrowRight from "../../assets/svg/icons-feather/arrow-right.svg"
import Button from "../../components/buttons/button"

const LatestPosts = () => {

  const posts = useStaticQuery(graphql`
    query {
      drupal {
        nl: nodeQuery(filter: {conditions: [
        {field: "type", operator: EQUAL, value: "recipe"},
        {field: "langcode", operator: EQUAL, value: "nl"},
        {field: "status", operator: EQUAL, value: "1"}]},
        limit: 3,
        sort: {field: "changed", direction: DESC}) {
          entities {
            ... on Drupal_NodeRecipe {
              entityTranslation(language: NL) {
                entityId,
                entityLabel,
                entityUrl {
                  path
                },
                ... on Drupal_NodeRecipe {
                  fieldImage {
                    ... on Drupal_FieldNodeRecipeFieldImage {
                      url
                      imageGatsbyFile {
                        childImageSharp {
                          fluid(maxWidth: 400, quality: 90) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`
  );

  let linkBlog = `/recepten`;

  return (
    <div className="latest-posts">
      <h2>Nieuwste 3 recepten</h2>
      {
        posts.drupal.nl.entities.map((node) => {
          if (node.entityTranslation === null) { return '' }
          // We strip the language from the Drupal alias.
          let alias = node.entityTranslation.entityUrl.path;
          return (
            <RecipeTeaser
              key={node.entityTranslation.entityId}
              title={node.entityTranslation.entityLabel}
              image={node.entityTranslation.fieldImage?.imageGatsbyFile.childImageSharp.fluid}
              link={alias.substring(3)}
            />
            )
          }
        )
      }
      <Button className="all-posts" link={linkBlog}><ArrowRight/>Alle recepten</Button>
    </div>
  )
};

export default LatestPosts;
